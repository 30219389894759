import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  description?: string
  languageCode: string
  mainServices?: ServiceProps[]
  servicesTitle?: string
  servicesList?: ServiceProps[]
  title?: string
}

export const RoomIntro = memo(function RoomIntro({
  description,
  languageCode,
  mainServices,
  servicesTitle,
  servicesList,
  title,
}: Props) {
  return (
    <Container row stretch tag="section" wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide>
        {mainServices ? (
          <>
            {servicesTitle ? (
              <FadeInUp>
                <ServicesTitle>{servicesTitle}</ServicesTitle>
              </FadeInUp>
            ) : null}
            <FadeInUp>
              <Services row wrap>
                {mainServices.map((item, index) => (
                  <Service key={index} {...item} />
                ))}
              </Services>
            </FadeInUp>
          </>
        ) : null}
        {servicesList ? (
          <>
            <FadeInUp>
              <ServicesTitle className="services-title-last">
                {`${useVocabularyData('services', languageCode)}:`}
              </ServicesTitle>
            </FadeInUp>
            <FadeInUp>
              <Services row wrap>
                {servicesList.map((item, index) => (
                  <Service key={index} {...item} />
                ))}
              </Services>
            </FadeInUp>
          </>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;
  padding: 5.6875rem 8.819vw 4.3125rem 12.639vw;

  @media (max-width: 1199px) {
    padding: 4rem 6vw 3.75rem 5vw;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 1.25rem 3.75rem 1.25rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 3.75rem 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
  margin-top: 1.875rem;
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 5.3125rem 12.639vw 5.3125rem 6.806vw;

  @media (max-width: 1199px) {
    padding: 4rem 10vw 4rem 5.75vw;
  }
  @media (max-width: 1023px) {
    padding: 3.75rem 1.25rem 3.75rem 1.25rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 2.8125rem 1.25rem;
  }
`

const ServicesTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
  &.services-title-last {
    margin-top: 5rem;
  }

  @media (max-width: 767px) {
    &.services-title-last {
      margin-top: 3.125rem;
    }
  }
`

const Services = styled(FlexBox)``
