import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  return (
    <Container dial={4} row>
      {icon ? <Icon src={icon} alt={label} width="20" height="20" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 50%;
  margin-top: 1.25rem;
  padding-right: 1.25rem;

  @media (max-width: 424px) {
    width: 100%;
    padding-right: 0;
  }
`

const Icon = styled.img`
  width: 1.25rem;
  height: auto;
  max-height: 1.25rem;
  margin-right: 0.9375rem;
`

const Label = styled.div`
  width: calc(100% - 2.1875rem);
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: -0.0313rem;
  line-height: 1.5rem;
`
